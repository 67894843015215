import styled from 'styled-components';
import { SubTitle } from './mainStyled.js'

export const MenuSection = styled.section`
    padding: 75px;
    margin-top: 225px;
    background-color: rgba(241, 241, 241, .75);
    text-align: center;
    @media all and (max-width: 650px){
        padding: 30px;
    }
    @media all and (max-width: 550px){
        margin-top: 100px;
    }
`;

export const Description = styled.div`
    text-align: center;
    margin-bottom: 50px;
    p {
        width: 70%;
        margin: 0 auto;
    }
`;

export const MenuTitle = styled.h2`
    display: inline-block;
    margin-bottom: 20px;
    font-family: Halant,sans-serif;

    &:after {
        content: '';
        display: block;
        width: 40px;
        height: 4px;
        background-color: orange;
        margin: 20px auto;
    }
`;

export const SubTitleStyled = styled(SubTitle)`
    font-family: Halant, sans-serif;
    margin-bottom: 15px;
`;

export const Meal = styled.div`
    margin-bottom: 50px;
    &:last-child {
        margin: 0;
    }
`;

export const MenuChoiceGroup = styled.section`
    padding: 75px;
    @media all and (max-width: 650px){
        padding: 30px;
    }
`;

export const OrderForm = styled.form`
    max-width: 80%;
    margin: 0 auto;

    input[type="text"] {
        width: 100%;
        border: 1px solid #f5f5f5;
        height: 40px;
        margin-bottom: 10px;
        transition: box-shadow 0.3s ease-in-out;
        font-size: 16px;
        &:focus {
            outline: none;
            border: 2px solid orange;
        }
    }

    label {
        width: 100%;
        display: block;
        text-align: left;
        font-size: .89rem;
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666666;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #ddd;
        border-radius: 100%;
        background: #ffffff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 12px;
        height: 12px;
        background: orange;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    input[type=submit]{
        border: 0;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 0;
        width: 7.5rem;
        border-radius: 15px;
        background-color: orange;
        color: white;
        height: 30px;
        transition: all .3s ease-in-out;
        cursor: pointer;
        font-size: 14px;
        &:hover {
            transform: scale(1.2);
        }
    }
`;

export const TableStyled = styled.table`
    border: none;
`;

export const TrStyled = styled.tr`
    border: none;
`;

export const ThStyled = styled.th`
    border: none;
    padding: 0;
    span {
        font-size: .8rem;
    }
`;

export const TdStyled = styled.td`
    border: none;
    font-size: .75rem;
    padding: 0;
    padding-right: 10px;
`;

export const TrStyledTitle = styled.tr`
    td {
        border: none;
    }
`;

export const AttForm = styled.form`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
        width: 50%;
        display: flex;
        @media all and (max-width: 650px){
            width: 90%;
        }
    }
    label {
        display: block;
        font-style: italic;
    }
    input[type="text"]{
        border: 0;
        border-bottom: 2px solid orange;
        flex-grow: 1;
    }
    input[type=submit]{
        border: 0;
        background: 0;
        color: orange;
        font-weight: bold;
        font-size: 2rem;
    }
`;