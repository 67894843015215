import React from 'react';

class Circle extends React.Component {
    render(){
        const { attendee } = this.props;
        const att = attendee.split(" ");
        return(
            <span>{att[0].charAt(0)}{att[1] && att[1].charAt(0)}</span>
        )
    }
}

export default Circle;
