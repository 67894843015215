import React from 'react';

import Circle from './circle';
import {
    MenuChoiceGroup,
    MenuTitle,
    Description,
    TableStyled,
    TrStyled,
    ThStyled,
    TdStyled,
    TrStyledTitle,
    AttForm,
    OrderForm,
} from './styles/menuStyled';

import {
    FormConfirmation
} from './styles/rsvpStyled.js'


class MenuSelect extends React.Component {
    state = {
        attendee: '',
        attendeeList: [],
        choices: [],
        starters: [],
        mains: [],
        deserts: [],
        diet: '',
        showForm: true,
    }

    encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }    

    handleAttendee = (ev) => {
        ev.preventDefault();
        this.setState({attendee: ev.target.value});
    }

    handleAttendeeSubmit = (ev) => {
        ev.preventDefault();
        this.setState({attendeeList: [...this.state.attendeeList, this.state.attendee]});
        this.setState({attendee: ''});
    }

    changeDesc( stateName, obj, name, dish ) {
        if(obj.find( person => person.name === name )){
            for (var i in obj) {
                if (obj[i].name === name) {
                    if(stateName === 'starters'){
                        this.state.starters[i].dish = dish;
                    }
                    if(stateName === 'mains'){
                        this.state.mains[i].dish = dish;
                    }
                    if(stateName === 'deserts'){
                        this.state.deserts[i].dish = dish;
                    }
                    this.forceUpdate();           
                    break;
                }
            }
        } else {
            this.setState({[stateName]: [...obj, dish] });
        }

     }

    handleStarterChoice = (ev) => {
        const dish = ev.target.value;
        const name = ev.target.getAttribute('data-attendee');
        const { starters } = this.state;

        if(starters.length < 1) { 
            this.setState({starters: [...starters, dish] }); 
        } else {
            this.changeDesc('starters', starters, name, dish);
        }
    }

    handleMainChoice = (ev) => {
        const dish = ev.target.value;
        const name = ev.target.getAttribute('data-attendee');
        const { mains } = this.state;

        if(mains.length < 1) { 
            this.setState({mains: [...mains, dish] }); 
        } else {
            this.changeDesc('mains', mains, name, dish);
        }
    }

    handleDesertChoice = (ev) => {
        const dish = ev.target.value;
        const name = ev.target.getAttribute('data-attendee');
        const { deserts } = this.state;

        if(deserts.length < 1) { 
            this.setState({deserts: [...deserts, dish] }); 
        } else {
            this.changeDesc('deserts', deserts, name, dish);
        }
    }

    handleDietChange = (ev) => {
        const value = ev.target.value;
        this.setState({diet: value});
    }

    sendOrder = (ev) => {
        console.log(this.state);
        ev.preventDefault();
        const formData = { starters: this.state.starters, mains: this.state.mains, deserts: this.state.deserts, diet: this.state.diet};

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: this.encode({ 'form-name': 'order', ...formData })
        })
            .then(() => setTimeout(() => {this.clearForm()}, 500 ))
            .catch(error => alert(error));
    }

    clearForm = () => {
        this.setState({
            attendeeList: '',
            diet: ''
        });
        setTimeout(() => { this.setState({ showForm: false }) }, 50);
    }

    render(){
        const { menu } = this.props;
        return(
            <MenuChoiceGroup>
                <Description>
                    <MenuTitle>Order</MenuTitle>
                    <p>Please use the form below to enter each guest by name and select each course per guest. If you have any questions please don't hesitate to get in touch with either of us.</p>
                    <p>Also if needed there is an extra input for any dietary / lifestyle requirements.</p>
                </Description>
                
                <AttForm onSubmit={this.handleAttendeeSubmit}>
                    <label htmlFor="attendee_input">Enter each, full name of guests attending (one at a time)</label>
                    <div>
                        <input required type="text" style={{fontSize: '16px', borderRadius: 0}} id="attendee_input" placeholder="eg. Daniella Taylor" name="new_attendee" onChange={this.handleAttendee} value={this.state.attendee}/>
                        <input type="submit" value="+" />
                    </div>
                </AttForm>

                <form name="order" data-netlify="true" style={{display: 'none'}}>
                    <input type="text" name="starters" />
                    <input type="text" name="mains" />
                    <input type="text" name="deserts" />
                    <input type="text" name="diet" />
                </form>
                
                {this.state.attendeeList.length > 0 &&
                    <OrderForm name="order" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={this.sendOrder}>
                        <input type="hidden" name="form-name" value="order" />
                        <TableStyled>
                            <thead>
                                <TrStyled>
                                    <ThStyled>&nbsp;</ThStyled>
                                    {this.state.attendeeList.map((att) => {
                                        return(
                                            <ThStyled>
                                                <Circle attendee={att}/>
                                            </ThStyled>
                                        )
                                    })}
                                </TrStyled>
                            </thead>
                            <tbody>
                                <TrStyledTitle><td>Starters</td></TrStyledTitle>
                                {menu.starters.map((sta, index) => {
                                    return(
                                        <TrStyled key={`sta-${index}`}>
                                            <TdStyled>{sta.dish}</TdStyled>
                                            <React.Fragment>
                                                {this.state.attendeeList.map((att, idx) => {
                                                    const attName = att.replace(/\s+/g, '-').toLowerCase();
                                                    return(
                                                        <TdStyled key={`${attName}-starter-${index}`}>
                                                            <input type="radio" id={`start-${index}-${idx}`} data-attendee={attName} value={`${att} = ${sta.dish}`} name={`starter-choice-${attName}`} key={`start-${index}`} onChange={this.handleStarterChoice} />
                                                            <label htmlFor={`start-${index}-${idx}`}>&nbsp;</label>                                                            
                                                        </TdStyled>
                                                    )
                                                })}
                                            </React.Fragment>
                                        </TrStyled>
                                    )
                                })}
                                <TrStyledTitle><td>Mains</td></TrStyledTitle>
                                {menu.mains.map((main, index) => {
                                    return(
                                        <TrStyled key={`main-${index}`}>
                                            <TdStyled>{main.dish}</TdStyled>
                                            <React.Fragment>
                                                {this.state.attendeeList.map((att, idx) => {
                                                    const attName = att.replace(/\s+/g, '-').toLowerCase();
                                                    return(
                                                        <TdStyled key={`${attName}-main-${index}`}>
                                                            <input type="radio" id={`main-${index}-${idx}`} data-attendee={attName} value={`${att} = ${main.dish}`} name={`main-choice-${attName}`} key={`main-${index}`} onChange={this.handleMainChoice}/>
                                                            <label htmlFor={`main-${index}-${idx}`}>&nbsp;</label>        
                                                        </TdStyled>
                                                    )
                                                })}
                                            </React.Fragment>
                                        </TrStyled>
                                    )
                                })}
                                <TrStyledTitle><td>Deserts</td></TrStyledTitle>
                                {menu.deserts.map((des, index) => {
                                    return(
                                        <TrStyled key={`des-${index}`}>
                                            <TdStyled>{des.dish}</TdStyled>
                                            <React.Fragment>
                                                {this.state.attendeeList.map((att, idx) => {
                                                    const attName = att.replace(/\s+/g, '-').toLowerCase();
                                                    return(
                                                        <TdStyled key={`${attName}-des-${index}`}>
                                                            <input type="radio" id={`des-${index}-${idx}`} data-attendee={attName} value={`${att} = ${des.dish}`} name={`desert-choice-${attName}`} key={`des-${index}`} onChange={this.handleDesertChoice}/>
                                                            <label htmlFor={`des-${index}-${idx}`}>&nbsp;</label>                                                            
                                                        </TdStyled>
                                                    )
                                                })}
                                            </React.Fragment>
                                        </TrStyled>
                                    )
                                })}
                            </tbody>
                        </TableStyled>
                        <label>Dietary Requirements: <input type="text" name="dietary" onChange={this.handleDietChange} value={this.state.diet} placeholder="Any dietary requirements, please specify here..."/></label>
                        <input type="submit" value="Send Order" />
                    </OrderForm>
                }
                <FormConfirmation showForm={!this.state.showForm}>Thank you!</FormConfirmation>
            </MenuChoiceGroup>
        )
    }
}

export default MenuSelect;
