import React from 'react';
import { StaticQuery, graphql } from "gatsby";

import Layout from '../components/layout';
import Main from '../components/main';
import MenuSelection from '../components/menuSelect';

import {
    MenuSection,
    SubTitleStyled,
    Meal,
    MenuTitle
} from '../components/styles/menuStyled';

const Menu = () => (
    <StaticQuery
        query={graphql`
            query food {
                contentJson {
                    food {
                        title
                        starters {
                            dish
                            dish
                        }
                        mains {
                            dish
                            dish
                            dish
                        }
                        deserts {
                            dish
                            dish
                        }
                    }
                }
            }
        `}
        render={ data => (
			<Layout>
                <Main menu/>
                <MenuSection>
                    <MenuTitle>Menu</MenuTitle>
                    <Meal>
                        <SubTitleStyled>Starters</SubTitleStyled>
                        {data.contentJson.food.starters.map((starter, index) => (
                            <p key={`starter_${index}`}>{starter.dish}</p>
                        ))}
                    </Meal>
                    <Meal>
                        <SubTitleStyled>Mains</SubTitleStyled>
                        {data.contentJson.food.mains.map((main, index) => (
                            <p key={`starter_${index}`}>{main.dish}</p>
                        ))}
                    </Meal>
                    <Meal>
                        <SubTitleStyled>Deserts</SubTitleStyled>
                        {data.contentJson.food.deserts.map((des, index) => (
                            <p key={`starter_${index}`}>{des.dish}</p>
                        ))}
                    </Meal>
                </MenuSection>
                <MenuSelection menu={data.contentJson.food}/>
            </Layout>
        )}
    />
)

export default Menu;
